import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PaymentMethod } from "@components/ZuoraPaymentDrawer/types";
import { DefaultRootState, useSelector } from "react-redux";
import { PaymentError } from "@utils/helpers";

export type ZuoraPaymentDrawerState = {
  paymentProviderId: string | null;
  paymentType: PaymentMethod | null;
  policyNumber: string | null;
  loading: boolean;
  open: boolean;
};

const initialState: ZuoraPaymentDrawerState = {
  paymentProviderId: null,
  paymentType: null,
  policyNumber: null,
  loading: false,
  open: false
};

export type OpenPaymentDrawerPayload = {
  paymentProviderId: string;
  policyNumber: string;
};
export type PaymentTypeUpdatedPayload = {
  paymentType: PaymentMethod;
};

export const zuora = createSlice({
  name: "zuora",
  initialState,
  reducers: {
    closePaymentDrawer: state => {
      state.open = false;
    },
    openPaymentDrawer: (
      state,
      action: PayloadAction<OpenPaymentDrawerPayload>
    ) => {
      state.loading = false;
      state.open = true;
      state.paymentProviderId = action.payload.paymentProviderId;
      state.paymentType = null;
      state.policyNumber = action.payload.policyNumber;
    },
    paymentPageLoaded: state => {
      state.loading = false;
    },
    paymentPageSubmitted: state => {
      state.loading = true;
    },
    paymentTypeUpdated: (
      state,
      action: PayloadAction<PaymentTypeUpdatedPayload>
    ) => {
      state.paymentType = action.payload.paymentType;
      state.loading = true;
    },
    paymentUpdateError: (
      state,
      // Action payload is unused because error state for each micro-frontend is managed within that application's store
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      action: PayloadAction<PaymentError | undefined>
    ) => {
      state.loading = false;
      state.open = false;
    },
    paymentUpdateSuccess: state => {
      state.loading = false;
      state.open = false;
    },
    clientError: state => {
      state.loading = false;
    }
  }
});

export const zuoraActions = zuora.actions;
export default zuora.reducer;

export type RootState = DefaultRootState & { zuora: ZuoraPaymentDrawerState };
// Utility hook to get zuora payment drawer state
export const useZuoraPaymentDrawerState = () =>
  useSelector<RootState, ZuoraPaymentDrawerState>(state => state.zuora);
